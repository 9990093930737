<template>
 <Layout :top-bar-title="title" :sections="sections">
    <ProductsTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from './navigationDrawerSections'
import ProductsTable from '../components/tables/ProductsTable'

export default {
  name: 'JunoProductList',
  components: { Layout, ProductsTable },
  data () {
    return {
      sections: sections,
      title: 'Lista de productos'
    }
  }
}
</script>

<style scoped>

</style>
