/**
 * This file define the list with the menu displayed by the NavigationDrawerComponent
 */

export default [
  {
    title: 'Listado de acuerdos',
    icon: 'fa-handshake',
    name: 'AgreementListOld'
  },
  {
    title: 'Productos',
    icon: 'fa-shopping-cart',
    name: 'JunoProductList'
  }
]
