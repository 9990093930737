<template>
  <CrudTable
    v-bind="crudTableConfig"
    :storeModule="storeModule"
    :addItems="false"
  >
    <template v-slot:[linkAttributes.path]="{ item }">
      <router-link :to="{ name: 'JunoProductDetail', params: { id: item.id }}">
        {{ Lget(item, linkAttributes.name, '-') || '-' }}
      </router-link>
    </template>
  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapState } from 'vuex'

export default {
  name: 'JunoProductList',
  components: { CrudTable },
  data () {
    return {
      storeModule: 'products'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),
    /**
     * Verifies if cod_material attribute exists, as to render the link slot with this attribute or the id
     * @returns {string}
     */
    linkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''

      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    }
  }
}
</script>
